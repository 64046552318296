import React from 'react';

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IEsimPrivate } from '../models/esim/IEsimPrivate';
import {
  get_esim_from_token_private,
  trigger_esim_from_token,
} from '../utils/requestHandler';
import Loader from '../components/Loader';

import EsimSwiperPartialActivation from '../components/private/EsimSwiperPartialActivation';
import EsimSwiperPartialSimChange from '../components/private/EsimSwiperPartialSimChange';
import { useNavigate } from 'react-router-dom';

const PrivateView = (): JSX.Element => {
  const [esim, setEsim] = useState<IEsimPrivate | null>(null);
  const [searchParams] = useSearchParams();

  const [error, setError] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const [loading, toggleLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    toggleLoading(true);

    const t = searchParams.get('esim');
    if (!t || (t && t.length !== 20)) {
      setError('Oops. Är du säker på att du använt rätt länk?');
      toggleLoading(false);
      return;
    }

    setError(null);
    setToken(t);

    get_esim_from_token_private(t)
      .then((data) => {
        setEsim(data);
        toggleLoading(false);
      })
      .catch(() => {
        setError('Oops. har du redan använt länken?');
        toggleLoading(false);
      });
  }, []);

  const onStartReplace = () => {
    toggleLoading(true);

    if (!token || (token && token.length !== 20)) {
      setError('Oops. Är du säker på att du använt rätt länk?');
      toggleLoading(false);
      return;
    }

    trigger_esim_from_token(token, false)
      .then(() => {
        navigate(0);
        toggleLoading(false);
      })
      .catch(() => {
        navigate(0);
        toggleLoading(false);
      });
  };

  return (
    <div className='view-container'>
      <Loader text={'Laddar eSIM'} loading={loading} />
      {error && <p>{error}</p>}

      {!error &&
        esim != null &&
        loading === false &&
        esim.orderType === 'newActivation' && (
          <EsimSwiperPartialActivation esimInformation={esim} />
        )}

      {!error &&
        esim != null &&
        loading === false &&
        esim.orderType === 'simChange' && (
          <EsimSwiperPartialSimChange
            esimInformation={esim}
            onStartReplace={onStartReplace}
          />
        )}
    </div>
  );
};

export default PrivateView;
