import React from 'react';

import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { get_esim_from_token_corporate } from '../utils/requestHandler';
import Loader from '../components/Loader';
import EsimSwiperPartialActivation from '../components/corporate/EsimSwiperPartialActivation';
import EsimSwiperPartialSimChange from '../components/corporate/EsimSwiperPartialSimChange';
import { IEsimCorporate } from '../models/esim/corporate/IEsimCorporate';

const CorporateView = (): JSX.Element => {
  const [esim, setEsim] = useState<IEsimCorporate | null>(null);
  const [searchParams] = useSearchParams();

  const [error, setError] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const [loading, toggleLoading] = useState<boolean>(false);

  const [counter, setCounter] = useState<number>(0);
  const counterRef = useRef(counter);
  useEffect(() => {
    counterRef.current = counter;
  });

  const valid_esim_to_reload = () => {
    if (!esim) return false;
    if (esim.esim.status.esimStatus === 'ProfileDeleted') return false;
    if (esim.esim.status.esimStatus === 'ProfileEnable') return false;

    return true;
  };

  // Initial load
  useEffect(() => {
    toggleLoading(true);

    const token = searchParams.get('esim');
    if (!token || (token && token.length !== 20)) {
      setError('Oops. Är du säker på att du använt rätt länk?');
      toggleLoading(false);
      return;
    }

    setError(null);
    setToken(token);

    get_esim_from_token_corporate(token)
      .then((data) => {
        setEsim(data);
        setCounter(0);
        toggleLoading(false);
      })
      .catch(() => {
        setError('Oops. har du redan använt länken?');
        toggleLoading(false);
      });

    return () => {
      setEsim(null);
      console.log('cleaning up');
    };
  }, [searchParams]);

  // setting up timer
  useEffect(() => {
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;

    function resetTimer() {
      setCounter(0);
    }
    return () => {
      window.removeEventListener('onload', resetTimer);
      document.removeEventListener('onmousemove', resetTimer);
      document.removeEventListener('onkeydown', resetTimer);
    };
  }, []);

  useEffect(() => {
    if (!esim) return;

    const interval = setInterval(() => {
      setCounter((state) => state + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [esim]);

  useEffect(() => {
    if (!valid_esim_to_reload()) return;

    if (!token || (token && token.length !== 20)) {
      setError('Oops. Är du säker på att du använt rätt länk?');
      toggleLoading(false);
      return;
    }
    const interval = setInterval(() => {
      if (counterRef.current > 30 * 1) {
        console.log('user is idle');
        return;
      }

      get_esim_from_token_corporate(token)
        .then((data) => {
          setEsim(data);
        })
        .catch(() => {
          setError('Oops. har du redan använt länken?');
        });
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [esim]);

  return (
    <div className='view-container'>
      <Loader text={'Laddar eSIM'} loading={loading} />
      {error && <p>{error}</p>}
      {(!error && esim && esim.ordertype === 'newActivation' && (
        <EsimSwiperPartialActivation esimInformation={esim} />
      )) ||
        (!error && esim && esim.ordertype === 'simChange' && (
          <EsimSwiperPartialSimChange esimInformation={esim} />
        )) ||
        (!error && esim && <p>Oops. Något gick fel.</p>)}
    </div>
  );
};

export default CorporateView;
