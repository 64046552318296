import React from 'react';
import './Esim.css';
import { standardDateTime, formatMobileNumber } from '../../utils/common';
import { IEsimCorporate } from '../../models/esim/corporate/IEsimCorporate';

interface Props {
  esimInformation: IEsimCorporate;
}

const Esim = (props: Props): JSX.Element => {
  const { esimInformation } = props;

  return (
    <div className='Esim' data-testid='esim-loaded'>
      {esimInformation && esimInformation.esim && (
        <>
          {esimInformation.esim.status.esimStatus === 'ProfileDeleted' && (
            <>
              {(esimInformation.username && (
                <>
                  <div className='esim-body'>
                    <p>Hej {esimInformation.username},</p>
                    <p>
                      Det verkar som ditt eSIM är förbrukat. Om du avinstallerat
                      ditt eSIM från din enhet kan du inte installera det igen.{' '}
                    </p>
                    <p>
                      Vänligen kontakta{' '}
                      <a
                        href='https://www.bredband2.com/foretag/foretagssupport/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        kundtjänst
                      </a>{' '}
                      och ha ditt ordernummer: {esimInformation.orderid} nära
                      till hands.
                    </p>
                  </div>
                </>
              )) || (
                <>
                  <div className='esim-body'>
                    <div className='esim-body'>
                      <p>Hej,</p>
                      <p>
                        Det verkar som ditt eSIM är förbrukat. Om du
                        avinstallerat ditt eSIM från din enhet kan du inte
                        installera det igen.
                      </p>
                      <p>
                        Vänligen kontakta{' '}
                        <a
                          href='https://www.bredband2.com/foretag/foretagssupport/'
                          target='_blank'
                          rel='noreferrer'
                        >
                          kundtjänst
                        </a>{' '}
                        och ha ditt ordernummer: {esimInformation.orderid} nära
                        till hands.
                      </p>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {esimInformation.esim.status.esimStatus === 'Released' && (
            <>
              {(esimInformation.username && (
                <>
                  <div className='esim-body'>
                    Vänligen installera ditt eSIM genom att scanna QR koden med
                    kameran i din mobiltelefon.
                  </div>
                  <div className='esim-body'>
                    {' '}
                    Ditt mobilnummer är{' '}
                    {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}
                  </div>
                </>
              )) || (
                <>
                  <div className='esim-body'>
                    Vänligen installera ditt eSIM genom att scanna QR koden med
                    kameran i din mobiltelefon.
                  </div>
                  <div className='esim-body'>
                    {' '}
                    Ditt mobilnummer är{' '}
                    {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}
                  </div>
                </>
              )}
            </>
          )}
          {(esimInformation.esim.status.esimStatus ===
            'ProfileDownloadPending' ||
            esimInformation.esim.status.esimStatus ===
              'ProfileDownloadStarted') && (
            <>
              {(esimInformation.username && (
                <>
                  <div className='esim-body'>
                    Du har nu påbörjat installationen av ditt eSIM{' '}
                    {standardDateTime(
                      String(esimInformation.esim.events.downloadStarted),
                    )}
                    . {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}
                  </div>
                </>
              )) || (
                <>
                  <div className='esim-body'>
                    Du har nu påbörjat installationen av ditt eSIM{' '}
                    {standardDateTime(
                      String(esimInformation.esim.events.downloadStarted),
                    )}
                    . {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}
                  </div>
                </>
              )}
            </>
          )}
          {(esimInformation.esim.status.esimStatus === 'ProfileEnable' ||
            esimInformation.esim.status.esimStatus === 'ProfileInstalled') && (
            <>
              {(esimInformation.username && (
                <>
                  <div className='esim-body'>
                    Du har slutfört installation av ditt eSIM{' '}
                    {standardDateTime(
                      String(esimInformation.esim.events.downloadSucceed),
                    )}
                    . Ditt mobilnummer är{' '}
                    {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}
                  </div>
                </>
              )) || (
                <>
                  <div className='esim-body'>
                    Du har slutfört installation av ditt eSIM{' '}
                    {standardDateTime(
                      String(esimInformation.esim.events.downloadSucceed),
                    )}
                    . Ditt mobilnummer är{' '}
                    {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}
                  </div>
                </>
              )}
            </>
          )}
          {(esimInformation.esim.acQrCodeUrl && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={esimInformation.esim.acQrCodeUrl}
                className='qrCodeTelia'
                alt='QRcode'
              ></img>
            </div>
          )) ||
            (esimInformation.esim.acQrCodeUrl === null && (
              <p>QRkod inte funnen.</p>
            ))}
        </>
      )}
    </div>
  );
};

export default Esim;
