import React from 'react';
import img_404 from '../resources/img/404.webp';

const BadRequestView = () => {
  return (
    <div className='view-container'>
      <img
        style={{ margin: '1rem', maxWidth: '75%' }}
        src={img_404}
        alt='404 bild för en sida som inte existerar'
      />
      <h1>Åh nej, sidan hittades inte.</h1>
      <p>
        Felet kan bero på att du angav en adress i webbläsaren som saknas eller
        gick till en sida som inte längre finns på sajten. Om du skrev in
        adressen för hand kan du kontrollera stavningen och försöka igen.
      </p>
    </div>
  );
};

export default BadRequestView;
