import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { IPayload } from '../models/bankIdPayload/IPayload';
import { IEsimCorporate as IGetResponseCorporate } from '../models/esim/corporate/IEsimCorporate';
import { IEsimPrivate as IGetResponsePrivate } from '../models/esim/IEsimPrivate';

const requestHandler = axios.create();

export const getESIM = async (key: string) => {
  return requestHandler.get(`/api/esim/?esim=${key}`).then((response) => {
    if (response.status === 200) {
      return response.data;
    } else
      throw new Error(
        `HTTPStatusCode: ${response.status}. Message: ${response.statusText}`,
      );
  });
};

export const get_esim_from_token_private = async (
  token: string,
): Promise<IGetResponsePrivate> => {
  return await requestHandler
    .get(`/api/token/private/?token=${token}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else
        throw new Error(
          `HTTPStatusCode: ${response.status}. Message: ${response.statusText}`,
        );
    });
};

export const get_esim_from_token_corporate = async (
  token: string,
): Promise<IGetResponseCorporate> => {
  return await requestHandler
    .get(`/api/token/corporate/?token=${token}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else
        throw new Error(
          `HTTPStatusCode: ${response.status}. Message: ${response.statusText}`,
        );
    });
};

export const trigger_esim_from_token = async (
  token: string,
  corporate: boolean,
) => {
  if (corporate) {
    throw Error('not implemented');
  } else {
    return await requestHandler
      .post(`/api/token/private/trigger/?token=${token}`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else
          throw new Error(
            `HTTPStatusCode: ${response.status}. Message: ${response.statusText}`,
          );
      });
  }
};

requestHandler.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = sessionStorage.getItem('token');

    if (token == null) {
      return config;
    }
    if (config.headers) {
      config.headers.Authorization = 'Bearer ' + String(token);
    }
    config.withCredentials = true;
    return config;
  },
  (error) => {
    Promise.reject(error).catch(() => {
      console.log('error: ' + error);
    });
  },
);

requestHandler.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      sessionStorage.removeItem('token');
      window.location.reload();
    }

    Promise.reject(error).catch(() => {
      console.log('error: ' + error);
    });
  },
);

export const initiateBankIDAuthentication = async (personalNumber: string) => {
  return await requestHandler
    .post(`/auth/bankid-init/`, { personalNumber: personalNumber })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else
        throw new Error(
          `HTTPStatusCode: ${response.status}. Message: ${response.statusText}`,
        );
    });
};
export const initiateWhitelistValidation = async (token: string) => {
  return await requestHandler
    .post(`/auth/whitelisted/`, { token })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 201) {
        sessionStorage.setItem('token', response.data);
        return null;
      } else {
        throw new Error(
          `HTTPStatusCode: ${response.status}. Message: ${response.statusText}`,
        );
      }
    });
};

export const initiateBankIDGetQrCode = async (payload: IPayload) => {
  const { personalNumber } = payload;

  return await requestHandler
    .post(`/auth/bankid-qrimage/`, { personalNumber })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 201) {
        sessionStorage.setItem('token', response.data);
        return null;
      } else
        throw new Error(
          `HTTPStatusCode: ${response.status}. Message: ${response.statusText}`,
        );
    });
};
