import './EsimSwiperPartial.css';

import React from 'react';
import Esim from './Esim';

import { Swiper, SwiperSlide } from 'swiper/react';
import { IEsimCorporate } from '../../models/esim/corporate/IEsimCorporate';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import { formatMobileNumber } from '../../utils/common';

interface Props {
  esimInformation: IEsimCorporate;
  //onStartReplace: React.MouseEventHandler<HTMLButtonElement>
}

const skipGuide = (props: Props): boolean => {
  const { esimInformation } = props;

  switch (esimInformation.esim.status.esimStatus) {
    case 'ProfileDeleted':
      return false;
    case 'ProfileEnable':
      return false;
    case 'ProfileInstalled':
      return false;
    default:
      return true;
  }
};

const EsimSwiperPartialSimChange = (props: Props): JSX.Element => {
  const { esimInformation } = props;

  return (
    <Swiper
      pagination={{
        type: 'progressbar',
      }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className='mySwiper'
    >
      {(skipGuide(props) && (
        <>
          <SwiperSlide>
            <div className='sliderPage'>
              {(esimInformation.username && (
                <>
                  <p>Hejsan {esimInformation.username}, </p>
                  <p>Vi har nu påbörjat simkortsbytet!</p>
                  <p>
                    Vänligen läs den här korta guiden om installation av eSIM
                  </p>
                </>
              )) || (
                <>
                  <p>Hejsan,</p>
                  <p>Vi har nu påbörjat simkortsbytet!</p>
                  <p>
                    Vänligen läs den här korta guiden om installation av eSIM
                  </p>
                </>
              )}
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='sliderPage'>
              <p>
                För att installera ditt eSIM ska du scanna en QR-kod med kameran
                i din mobil.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='sliderPage'>
              <p>
                Enklast är att du öppnar den här sidan på annan skärm än din
                mobil så det är lätt att scanna med mobilkameran.
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='sliderPage'>
              <p>
                Detta gäller mobilnummer{' '}
                {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}.
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='sliderPage'>
              <p>Det kan vara bra att veta att eSIM inte kommer med PIN-kod.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='sliderPage'>
              <p>eSIM på Iphone och Ipad</p>
              <ol className='sliderPageList'>
                <li>Ta (eventuellt) ut det fysiska simkortet ur enheten.</li>
                <li>Gå till enhetens inställningar.</li>
                <li>
                  Välj{' '}
                  <strong>
                    <em>Mobilnät</em>
                  </strong>
                  .
                </li>
                <li>
                  Välj{' '}
                  <strong>
                    <em>Lägg till abonnemang</em>
                  </strong>
                  .
                </li>
                <li>Scanna QR-koden med enhetens kamera.</li>
                <li>Följ instruktionerna.</li>
              </ol>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='sliderPage'>
              <p>eSIM på Android enheter</p>
              <ol className='sliderPageList'>
                <li>Ta (eventuellt) ut det fysiska simkortet ur enheten.</li>
                <li>Gå till enhetens inställningar.</li>
                <li>
                  Välj{' '}
                  <strong>
                    <em>Anslutningar</em>
                  </strong>
                </li>
                <li>
                  Välj{' '}
                  <strong>
                    <em>SIM-kortshanteraren</em>
                  </strong>
                </li>
                <li>
                  Välj{' '}
                  <strong>
                    <em>Lägg till abonnemang</em>
                  </strong>
                </li>
                <li>
                  Välj{' '}
                  <strong>
                    <em>Lägg till med QRkod</em>
                  </strong>
                </li>
                <li>Scanna QR-koden med enhetens kamera.</li>
                <li>Följ instruktionerna.</li>
              </ol>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
              <div className='sliderPage'>
                <p>För mer ytterligare information.</p>
                <p>
                  <a href='https://support.apple.com/sv-se/HT212780' target={"_blank"} rel="noreferrer" >eSIM-kort på Iphone</a>
                </p>
                <p>
                  <a href='https://www.samsung.com/se/wearables/galaxy-watch/esim/' target={"_blank"} rel="noreferrer" >Samsung Galaxy Watch och Galaxy Wearable-appen</a>
                </p>
              </div>
            </SwiperSlide> */}
          <SwiperSlide>
            <div className='sliderPage'>
              <p>
                Efter du scannat och påbörjat installation av ditt eSIM kan det
                ta ett par minuter innan{' '}
                {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')} läggs
                över på eSIM.
              </p>
              <p>Vänligen ha tålamod och prova slå på och av flygplansläge.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <Esim esimInformation={esimInformation} />{' '}
          </SwiperSlide>
        </>
      )) || (
        <SwiperSlide>
          <Esim esimInformation={esimInformation} />{' '}
        </SwiperSlide>
      )}
    </Swiper>
  );
};

export default EsimSwiperPartialSimChange;
