import './EsimSwiperPartial.css';

import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { IEsimCorporate } from '../../models/esim/corporate/IEsimCorporate';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

import { formatMobileNumber } from '../../utils/common';
import Esim from './Esim';

interface Props {
  esimInformation: IEsimCorporate;
}

const skipGuide = (props: Props): boolean => {
  const { esimInformation } = props;

  switch (esimInformation.esim.status.esimStatus) {
    case 'ProfileDeleted':
      return false;
    case 'ProfileEnable':
      return false;
    case 'ProfileInstalled':
      return false;
    default:
      return true;
  }
};

const EsimSwiperPartialActivation = (props: Props): JSX.Element => {
  const { esimInformation } = props;

  return (
    <Swiper
      pagination={{
        type: 'progressbar',
      }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className='mySwiper'
    >
      {(skipGuide(props) && (
        <>
          <SwiperSlide>
            <div className='sliderPage'>
              {(esimInformation.username && (
                <>
                  <p>Hejsan {esimInformation.username}, </p>
                  <p>Dags att installera ditt eSIM?</p>
                  <p>Vänligen läs den här korta guiden först</p>
                </>
              )) || (
                <>
                  <p>Hejsan,</p>
                  <p>Dags att installera ditt eSIM?</p>
                  <p>Vänligen läs den här korta guiden först</p>
                </>
              )}
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='sliderPage'>
              <p>
                För att installera ditt eSIM ska du scanna en QR-kod med kameran
                i din mobil.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='sliderPage'>
              <p>
                Enklast är att du öppnar den här sidan på annan skärm än din
                mobil så det är lätt att scanna med mobilkameran.
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='sliderPage'>
              <p>
                Ditt mobilnummer är{' '}
                {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='sliderPage'>
              <p>Det kan vara bra att veta att eSIM inte kommer med PIN-kod.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='sliderPage'>
              <p>eSIM på Iphone och Ipad</p>
              <ol className='sliderPageList'>
                <li>Ta (eventuellt) ut det fysiska simkortet ur enheten.</li>
                <li>Gå till enhetens inställningar.</li>
                <li>
                  Välj{' '}
                  <strong>
                    <em>Mobilnät</em>
                  </strong>
                  .
                </li>
                <li>
                  Välj{' '}
                  <strong>
                    <em>Lägg till abonnemang</em>
                  </strong>
                  .
                </li>
                <li>Scanna QR-koden med enhetens kamera.</li>
                <li>Följ instruktionerna.</li>
              </ol>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='sliderPage'>
              <p>eSIM på Android enheter</p>
              <ol className='sliderPageList'>
                <li>Ta (eventuellt) ut det fysiska simkortet ur enheten.</li>
                <li>Gå till enhetens inställningar.</li>
                <li>
                  Välj{' '}
                  <strong>
                    <em>Anslutningar</em>
                  </strong>
                </li>
                <li>
                  Välj{' '}
                  <strong>
                    <em>SIM-kortshanteraren</em>
                  </strong>
                </li>
                <li>
                  Välj{' '}
                  <strong>
                    <em>Lägg till abonnemang</em>
                  </strong>
                </li>
                <li>
                  Välj{' '}
                  <strong>
                    <em>Lägg till med QRkod</em>
                  </strong>
                </li>
                <li>Scanna QR-koden med enhetens kamera.</li>
                <li>Följ instruktionerna.</li>
              </ol>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='sliderPage'>
              <p>
                Efter du scannat och påbörjat installation kan det ta ett par
                minuter innan ditt abonnemang är fullt aktiverat hos oss.
              </p>
              <p>
                Kom ihåg att ditt abonnemang <strong>inte aktiveras</strong>{' '}
                förrän din nummerflytt eller det datum som är registrerat i
                ordern.
              </p>
              <p>
                Om du installerar ditt esim i förväg kan du behöva starta om
                mobilen eller slå på och av flygplansläge på aktiveringsdagen.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <Esim esimInformation={esimInformation} />
          </SwiperSlide>

          {(esimInformation.esim.status.esimStatus === 'ProfileEnable' ||
            esimInformation.esim.status.esimStatus === 'ProfileDisable') && (
            <SwiperSlide>
              <div className='sliderPage'>
                <p>
                  Kom ihåg att ditt abonnemang <strong>inte aktiveras</strong>{' '}
                  förrän din nummerflytt eller det datum som är registrerat i
                  ordern.
                </p>
                <p>
                  Om du installerat ditt esim i förväg (före nummerflytt eller
                  datum i ordern) kan du behöva starta om mobilen eller slå på
                  och av flygplansläge på aktiveringsdagen.
                </p>
              </div>
            </SwiperSlide>
          )}
        </>
      )) || (
        <SwiperSlide>
          <Esim esimInformation={esimInformation} />
        </SwiperSlide>
      )}
    </Swiper>
  );
};

export default EsimSwiperPartialActivation;
