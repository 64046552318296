import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import LoginBankid from "../../views/LoginBankid";

export function BankIdValidation() {

  const [isBankIdValidated, setIsBankIdValidated] =
    useState<boolean>(false);


  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (sessionStorage.getItem('token') != null) {
      setIsBankIdValidated(true);
      return
    }
    const token = searchParams.get('esim');
    if (!token || (token && token.length !== 20)) {
      window.alert('Oops. Är du säker på att du använt rätt länk?');
      return;
    }
  }, []);

  return (isBankIdValidated === true)
    ? <Outlet />
    : <LoginBankid />


}
