import React from 'react';
import './Loader.css';

interface Props {
  text: string;
  loading: boolean;
}

const Loader = (props: Props): JSX.Element => {
  const { text, loading } = props;

  return (
    <>
      {loading && (
        <div className='loader'>
          <div className='balls'>
            <div className='ball bouncingball1'></div>
            <div className='ball bouncingball2'></div>
            <div className='ball bouncingball3'></div>
          </div>
          <div className='loader-text'>{text}</div>
        </div>
      )}
    </>
  );
};

export default Loader;
