import { IDateOptions } from '../models/date/IDateOptions';

const options: IDateOptions = {
  standard_date: { year: 'numeric', month: 'numeric', day: 'numeric' },
  standard_date_and_time: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  standard_time: {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  standard_time_with_milliseconds: {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    fractionalSecondDigits: 3,
  },
};

export const standardDate = (string: string): string => {
  const date = string ? new Date(string) : new Date();
  return new Intl.DateTimeFormat('sv-SE', options.standard_date).format(date);
};

export const standardDateTime = (string: string): string => {
  const date = string ? new Date(string) : new Date();
  return new Intl.DateTimeFormat(
    'sv-SE',
    options.standard_date_and_time,
  ).format(date);
};

export const standardTime = (string: string): string => {
  const date = string ? new Date(string) : new Date();
  return new Intl.DateTimeFormat('sv-SE', options.standard_time).format(date);
};

export const formatMobileNumber = (
  number: string,
  format: string,
): string | boolean => {
  if (typeof number !== 'string') {
    return false;
  }
  const n = number.replace(/\D/g, '');
  const prefix: string[] = ['70', '72', '73', '76', '79'];

  for (const p in prefix) {
    if (
      n.indexOf(prefix[p]) > -1 &&
      n.substring(n.indexOf(prefix[p]), n.length).length === 9
    ) {
      if (format && format === 'NATIONAL') {
        let fn = '0';
        const pdx = n.indexOf(prefix[p]);
        for (let i = pdx; i <= pdx + 8; i++) {
          fn +=
            (i === pdx + 2 ? '-' : i === pdx + 5 || i === pdx + 7 ? ' ' : '') +
            n.substr(i, 1);
        }
        return fn;
      }
      return (
        (format && format === 'INTERNATIONAL' ? '+46' : '0') +
        n.substring(n.indexOf(prefix[p]), n.length)
      );
    }
  }
  return false;
};
