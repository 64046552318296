import React, { useState, useEffect } from 'react';

interface Props {
  url: string;
  reloadTime: number;
}

function BankIdQrCode(props: Props): JSX.Element {
  const { url, reloadTime } = props;

  const [imageUrl, setImageUrl] = useState(url); // Replace with your initial image URL

  useEffect(() => {
    const interval = setInterval(() => {
      // Generate a random parameter to force image reload (prevent caching)
      const randomParam = Math.random();
      const newImageUrl = `${url}?time=${randomParam}`;
      setImageUrl(newImageUrl);
    }, reloadTime);

    // Stop the interval after x seconds
    setTimeout(() => {
      clearInterval(interval);
      console.log('reloadTime', { reloadTime });
    }, 2000 * 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <img src={imageUrl} alt='BankId QRcode' />
    </div>
  );
}

export default BankIdQrCode;
