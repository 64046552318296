import React, { useEffect } from 'react';
import './Esim.css';
import { formatMobileNumber } from '../../utils/common';
import QRCode from 'qrcode';
import { IEsimPrivate } from '../../models/esim/IEsimPrivate';

interface Props {
  esimInformation: IEsimPrivate;
}

const Esim = (props: Props): JSX.Element => {
  const { esimInformation } = props;

  useEffect(() => {
    if (esimInformation.matchingId == null) {
      return;
    }
    if (esimInformation.matchingId === '') {
      return;
    }
    if (esimInformation.state === 'ENABLED') {
      return;
    }
    const canvas = document.getElementById('canvas');
    QRCode.toCanvas(
      canvas,
      esimInformation.matchingId,
      { width: 200 },
      function (error) {
        if (error) console.error(error);
        console.log('success!');
      },
    );
  }, [esimInformation]);

  return (
    <div className='Esim' data-testid='esim-loaded'>
      {esimInformation && (
        <>
          {esimInformation.state === 'DELETED' && (
            <>
              {(esimInformation.userName && (
                <>
                  <div className='esim-body'>
                    <p>Hej {esimInformation.userName},</p>
                    <p>
                      Det verkar som ditt eSIM är förbrukat. Om du avinstallerat
                      ditt eSIM från din enhet kan du inte installera det igen.
                    </p>
                    <p>
                      Vänligen kontakta{' '}
                      <a
                        href='https://www.bredband2.com/privat/kundservice/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        kundtjänst
                      </a>{' '}
                    </p>
                  </div>
                </>
              )) || (
                <>
                  <div className='esim-body'>
                    <div className='esim-body'>
                      <p>Hej,</p>
                      <p>
                        Det verkar som ditt eSIM är förbrukat. Om du
                        avinstallerat ditt eSIM från din enhet kan du inte
                        installera det igen.
                      </p>
                      <p>
                        Vänligen kontakta{' '}
                        <a
                          href='https://www.bredband2.com/privat/kundservice/'
                          target='_blank'
                          rel='noreferrer'
                        >
                          kundtjänst
                        </a>{' '}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {esimInformation.state === 'RELEASED' && (
            <>
              {(esimInformation.userName && (
                <>
                  <div className='esim-body'>
                    Vänligen installera ditt eSIM genom att scanna QR koden med
                    kameran i din mobiltelefon.
                  </div>
                  <div className='esim-body'>
                    {' '}
                    Ditt mobilnummer är{' '}
                    {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}
                  </div>
                </>
              )) || (
                <>
                  <div className='esim-body'>
                    Vänligen installera ditt eSIM genom att scanna QR koden med
                    kameran i din mobiltelefon.
                  </div>
                  <div className='esim-body'>
                    {' '}
                    Ditt mobilnummer är{' '}
                    {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}
                  </div>
                </>
              )}
            </>
          )}

          {esimInformation.state === 'DOWNLOADED' && (
            <>
              {(esimInformation.userName && (
                <>
                  <div className='esim-body'>
                    Du har nu påbörjat installationen av ditt eSIM.{' '}
                    {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}
                  </div>
                </>
              )) || (
                <>
                  <div className='esim-body'>
                    Du har nu påbörjat installationen av ditt eSIM.{' '}
                    {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}
                  </div>
                </>
              )}
            </>
          )}
          {(esimInformation.state === 'INSTALLED' ||
            esimInformation.state === 'ENABLED') && (
            <>
              {(esimInformation.userName && (
                <>
                  <div className='esim-body'>
                    Du har slutfört installation av ditt eSIM. Ditt mobilnummer
                    är {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}
                  </div>
                </>
              )) || (
                <>
                  <div className='esim-body'>
                    Du har slutfört installation av ditt eSIM. Ditt mobilnummer
                    är {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}
                  </div>
                </>
              )}
            </>
          )}
          {<canvas id='canvas'></canvas>}
        </>
      )}
    </div>
  );
};

export default Esim;
