import React, { useEffect, useState } from "react";
import { initiateWhitelistValidation as initiateWhitelistValidation } from "../../utils/requestHandler";
import { Outlet, useSearchParams } from "react-router-dom";
import LoginBankid from "../../views/LoginBankid";


export function BankIdWhitelist() {

  const [isWhitelistValidated, setIsWhitelistValidated] =
    useState<boolean>(false);

  const [loaded, setLoaded] =
    useState<boolean>(false);

  const [searchParams] = useSearchParams();

  const handleWhitelisting = async (token: string): Promise<void> => {
    try {
      initiateWhitelistValidation(token).finally(() => {
        const t = sessionStorage.getItem('token');
        if (t != null) {
          setIsWhitelistValidated(true);
        }
        setLoaded(true)
      });
    } catch (error) {
      window.alert('Oops. Är du säker på att du använt rätt länk?');
    }
  };

  useEffect(() => {
    if (loaded) { return }
    if (sessionStorage.getItem('token') != null) {
      setIsWhitelistValidated(true);
      setLoaded(true)
      return
    }
    const token = searchParams.get('esim');
    if (!token || (token && token.length !== 20)) {
      window.alert('Oops. Är du säker på att du använt rätt länk?');
      return;
    }
    handleWhitelisting(token);
  }, []);


  return loaded === true
    ? (
      (isWhitelistValidated === true)
        ? <Outlet />
        : <LoginBankid />
    )
    : <p>Loading...</p>

}
