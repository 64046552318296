import './EsimSwiperPartial.css';
import React, { useEffect, useRef, useState } from 'react';
import Esim from './Esim';
import { useSearchParams } from 'react-router-dom';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import { IEsimPrivate } from '../../models/esim/IEsimPrivate';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';
import { formatMobileNumber } from '../../utils/common';
import QRCodeExpired from '../../resources/img/QRCodeExpired.png';
import { Col, Row, Spinner } from 'react-bootstrap';

interface Props {
  esimInformation: IEsimPrivate;
  onStartReplace: React.MouseEventHandler<HTMLButtonElement>;
}

function useInterval(callback: { (): void } | null, delay: number) {
  const savedCallback = useRef<{ (): void } | null>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect((): (() => void) | void => {
    function tick() {
      if (!savedCallback.current) {
        console.error('savedCallback is null, tick function failed');
        return;
      }
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function Counter(): JSX.Element {
  const [count, setCount] = useState(0);
  useInterval(() => {
    // Your custom logic here
    window.location.reload();

    setCount(count + 1);
  }, 15000);

  return (
    <Spinner animation='border' role='status'>
      <span className='visually-hidden'>Laddar...</span>
    </Spinner>
  );
}

const EsimSwiperPartialSimChange = (props: Props): JSX.Element => {
  const { esimInformation, onStartReplace } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [initalized, setInitalized] = useState(false);
  const [autoReload, setAutoReload] = useState(false);

  // // Mocked
  // const [esim] = useState({
  //   status: "consumed",
  //   iccid: "75012000000437",
  //   userName: "Thomas",
  //   msisdn: "46732710326",
  //   orderType: "simChange",
  //   matchingId: "LPA:1$rsp-3010.idemia.io$4Y0WM-XVARY-68GHE-BNDSJ$",
  //   // matchingId: "LPA:1$rsp-3010.idemia.io$$", // non release matchingid..
  //   state: "RELEASED",
  //   porting: "False",
  //   ssn: '8610143656'
  // })

  const skipGuide = (props: Props): boolean => {
    const { esimInformation } = props;

    switch (esimInformation.state) {
      case 'AVAILABLE':
        return true;
      case 'RELEASED':
        return true;
      default:
        return false;
    }
  };

  const handleSlideChange = (swiper: SwiperClass) => {
    if (!initalized) {
      return;
    }
    searchParams.set('slide', String(swiper.activeIndex));
    setSearchParams(searchParams);

    if (
      swiper.activeIndex === 8 &&
      esimInformation.state === 'RELEASED' &&
      esimInformation.matchingId.length <= 49
    ) {
      setAutoReload(true);
      return;
    }

    if (swiper.activeIndex === 8 && esimInformation.state === 'AVAILABLE') {
      setAutoReload(true);
      return;
    }
    setAutoReload(false);
  };

  useEffect(() => {
    const cs = searchParams.get('slide');
    if (cs == null) {
      setInitalized(true);
      return;
    }
    setCurrentSlide(Number(cs));
    setInitalized(true);
  }, []);

  return (
    <>
      {esimInformation.status === 'free' ? (
        <>
          <Row className='mt-5'>
            <Col
              className='mb-2'
              xs={{
                span: 8,
                offset: 2,
              }}
            >
              <p>Är du säker på att du vill fortsätta med simkortsbytet?</p>
              <p>Dubbelkolla att du har stöd för eSIM i din mobiltelefon.</p>
              <p>
                När du startar simkortsbytet kommer vi genomföra simkortsbytet
                generera QRkod för installation av ditt nya eSIM.
              </p>
            </Col>
            <Col
              className='mb-2 d-flex justify-content-center'
              xs={{ span: 8, offset: 2 }}
            >
              <button className='btn' onClick={onStartReplace}>
                Starta Simkortsbyte
              </button>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
      {esimInformation.status === 'consumed' ? (
        <>
          {(skipGuide(props) && (
            <>
              {initalized && (
                <Swiper
                  pagination={{
                    type: 'progressbar',
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className='mySwiper'
                  initialSlide={currentSlide}
                  onSlideChange={handleSlideChange}
                >
                  <SwiperSlide>
                    <div className='sliderPage'>
                      {(esimInformation.userName && (
                        <>
                          <p>Hejsan {esimInformation.userName}, </p>
                          <p>Vi har nu påbörjat simkortsbytet!</p>
                          <p>
                            Vänligen läs den här korta guiden om installation av
                            eSIM
                          </p>
                        </>
                      )) || (
                        <>
                          <p>Hejsan,</p>
                          <p>Vi har nu påbörjat simkortsbytet!</p>
                          <p>
                            Vänligen läs den här korta guiden om installation av
                            eSIM
                          </p>
                        </>
                      )}
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className='sliderPage'>
                      <p>
                        För att installera ditt eSIM ska du scanna en QR-kod med
                        kameran i din mobil.
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='sliderPage'>
                      <p>
                        Enklast är att du öppnar den här sidan på annan skärm än
                        din mobil så det är lätt att scanna med mobilkameran.
                      </p>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className='sliderPage'>
                      <p>
                        Detta gäller mobilnummer{' '}
                        {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}
                        .
                      </p>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className='sliderPage'>
                      <p>
                        Det kan vara bra att veta att eSIM inte kommer med
                        PIN-kod.
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='sliderPage'>
                      <p>eSIM på Iphone och Ipad</p>
                      <ol className='sliderPageList'>
                        <li>
                          Ta (eventuellt) ut det fysiska simkortet ur enheten.
                        </li>
                        <li>Gå till enhetens inställningar.</li>
                        <li>
                          Välj{' '}
                          <strong>
                            <em>Mobilnät</em>
                          </strong>
                          .
                        </li>
                        <li>
                          Välj{' '}
                          <strong>
                            <em>Lägg till abonnemang</em>
                          </strong>
                          .
                        </li>
                        <li>Scanna QR-koden med enhetens kamera.</li>
                        <li>Följ instruktionerna.</li>
                      </ol>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='sliderPage'>
                      <p>eSIM på Android enheter</p>
                      <ol className='sliderPageList'>
                        <li>
                          Ta (eventuellt) ut det fysiska simkortet ur enheten.
                        </li>
                        <li>Gå till enhetens inställningar.</li>
                        <li>
                          Välj{' '}
                          <strong>
                            <em>Anslutningar</em>
                          </strong>
                        </li>
                        <li>
                          Välj{' '}
                          <strong>
                            <em>SIM-kortshanteraren</em>
                          </strong>
                        </li>
                        <li>
                          Välj{' '}
                          <strong>
                            <em>Lägg till abonnemang</em>
                          </strong>
                        </li>
                        <li>
                          Välj{' '}
                          <strong>
                            <em>Lägg till med QRkod</em>
                          </strong>
                        </li>
                        <li>Scanna QR-koden med enhetens kamera.</li>
                        <li>Följ instruktionerna.</li>
                      </ol>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='sliderPage'>
                      <p>
                        Efter du scannat och påbörjat installation av ditt eSIM
                        kan det ta ett par minuter innan{' '}
                        {formatMobileNumber(esimInformation.msisdn, 'NATIONAL')}{' '}
                        eSIM är aktivt.
                      </p>
                      <p>
                        Vänligen ha tålamod och prova slå på och av
                        flygplansläge.
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {esimInformation.state === 'AVAILABLE' ? (
                      <div className='sliderPage'>
                        {autoReload && <Counter />}
                        <p>
                          Vi är inte riktigt klara med aktiveringen av ditt esim
                          ännu. Vänligen vänta en stund och ladda om sidan. Du
                          bör se en QR-kod om en stund, det kan ta upp till 15
                          minuter.
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}

                    {esimInformation.state === 'RELEASED' ? (
                      <>
                        {esimInformation.matchingId.length > 26 ? (
                          <Esim esimInformation={esimInformation} />
                        ) : (
                          <div className='sliderPage'>
                            {autoReload && <Counter />}
                            <p>
                              Vi är inte riktigt klara med aktiveringen av ditt
                              esim ännu. Vänligen vänta en stund och ladda om
                              sidan. Du bör se en QR-kod om en stund, det kan ta
                              upp till 15 minuter.
                            </p>
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {esimInformation.state === 'ENABLED' ? (
                      <p>Installerat</p>
                    ) : (
                      <></>
                    )}
                  </SwiperSlide>
                </Swiper>
              )}
            </>
          )) || (
            <>
              <p>eSIM installerat.</p>
              <img
                style={{ width: '200px' }}
                src={QRCodeExpired}
                alt='Överstruken QRkod för att indikera att den är förbrukad.'
              />
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default EsimSwiperPartialSimChange;
